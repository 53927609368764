<template>
    <div>
        
        <van-collapse v-model="chil_activeName">
            <van-collapse-item name="1">
                <template #title>
                    <div class="lineDiv">
                        <div class="line"></div>
                        <span>{{chil_titleName}}</span>
                    </div>
                </template>
                <uploader-image :bigTitle="bigTitle+(index+1)" :smellTitle="smellTitle" 
                    :uploaderImage="loderImage" :isnum="index+1" :item="item"
                    v-for="(item,index) in uploaderArr" :key="index" 
                    @deleteIndex="deleteEduction" :is-power="isPower" :is-water="isWater"
                    >
                </uploader-image>
                <div class="addDiv" @click="toClickAdd">
                    <img src="../../assets/image/powerMeasure/add.png" alt="">
                    <p>增加票据</p>
                </div>
                
            </van-collapse-item>
        </van-collapse>

    </div>
</template>
<script>
import UploaderImage from '../../components/uploaderImage/UploaderImage.vue'

    export default{
        name:'measure',
        data(){
            return{
                chil_titleName : this.titleName,
                chil_activeName : this.activeName,
                uploaderArr:[{}],
                bigTitle:'',
                smellTitle:'',
                loderImage:'',
                isPower:false,
                isWater:false
            }
        },
        created(){
            switch (this.titleName) {
                case '发票':
                        this.bigTitle = '发票'
                        this.smellTitle = '发票时间'
                        this.loderImage = 'powerImage.png'
                        this.isPower = true
                    break;
                case '水单':
                        this.bigTitle = '水单'
                        this.smellTitle = '水单时间'
                        this.loderImage = 'waterImage.png'
                        this.isWater = true
                    break;
                case '核稽表':
                        this.bigTitle = '核稽表'
                        this.smellTitle = '核稽表时间'
                        this.loderImage = 'checkImage.png'
                      
                    break;
            
                default:
                    break;
            }
        },
        components:{UploaderImage},
        props:{
            titleName:{
                type:String,
                default:'原始用电费用'
            },
            activeName:{
                type:Array,
                default:function(){
                    return ['1']
                }
            }
        },
        methods:{
            deleteEduction(index){
                index = index -1
                 this.uploaderArr.splice(index,1);
            },
            toClickAdd(){
                this.uploaderArr.push({});
            },
        }
    }
</script>
<style scoped>
@import "../../assets/css/measure.css";
.addDiv{
    border-top: solid 1px #EEF0FC;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
}
.addDiv img{
    width: 41px;
    height: 41px;
    margin-right: 12px;
}
.addDiv p{
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #01A4A4;
    line-height: 130px;
}
</style>
