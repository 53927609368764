<template>
    <div >
        
        <div v-show="isShow">
            <van-nav-bar title="新客户申请" :fixed=isFixed />
            <van-form @submit="onSubmit">
            <div class="powerDiv">
                <van-collapse v-model="activeName_1">
                    <van-collapse-item name="apply">
                        <template #title>
                            <div class="lineDiv">
                                <div class="line"></div>
                                <span>客户信息</span>
                            </div>
                        </template>
                           
                            <van-field
                                readonly
                                clickable
                                name="titleName"
                                :value="titleName"
                                :label=enName
                                placeholder="请选择"
                                @click="toSelectJxs"
                                />
                                <van-popup v-model="showJxs" position="bottom">
                                <van-picker show-toolbar :columns="jxsArr" value-key="titleName" @confirm="onConfirm" @cancel="showJxs = false" />
                                </van-popup> 
                            <van-field v-model="distributorPhone" name="distributorPhone" :label=enPhone readonly />
                          
                            <van-field
                                readonly
                                clickable
                                name="salesman"
                                :value="salesmanName"
                                :label=usName
                                placeholder="请选择"
                                @click="showYwy = true"
                                />
                                <van-popup v-model="showYwy" position="bottom">
                                <van-picker show-toolbar :columns="ywyArr" value-key="salesman" @confirm="onConfirmYwy" @cancel="showYwy = false"/>
                                </van-popup> 
                            <van-field v-model="salesmanPhone" name="salesmanPhone" :label=usPhone  readonly/>
                            <van-field v-model="corporateName" name="corporateName" label="公司名称" placeholder="请输入" />
                            <van-field v-model="customerName" name="customerName" label="联系人姓名" placeholder="请输入" />
                            <van-field v-model="companyPhone" name="companyPhone" label="联系人手机号" placeholder="请输入" />
                            <van-field v-model="meterNumber" name="meterNumber" label="电表户号" placeholder="请输入" />
                    </van-collapse-item>
                </van-collapse>
                <Measure titleName="发票" :activeName=activeName_2 class="toTopHigh"></Measure>
                <Measure titleName="水单" :activeName=activeName_3 class="toTopHigh"></Measure>
                <Measure titleName="核稽表" :activeName=activeName_4 class="toTopHigh"></Measure>
              
                <button class="tryTest" native-type="submit">试算</button> 
                <div class="salePlan" @click="overlayShow = !overlayShow">优惠方案</div>
                <van-overlay :show="overlayShow">
                <div class="wrapper" >
                    <table>
                        <tr class="titleTB">
                            <th>原始均价(元/度)</th>
                            <th>折扣</th>
                            <th>折扣均价(元/度)</th>
                        </tr>
                        <tr>
                            <td>≤ ¥ 1.50</td>
                            <td class="colorText">80%</td>
                            <td>¥ 1.20</td>
                        </tr>
                        <tr>
                            <td>≤ ¥ 1.00</td>
                            <td class="colorText">85%</td>
                            <td>¥ 0.85</td>
                        </tr>
                        <tr>
                            <td>≤ ¥ 0.90</td>
                            <td class="colorText">90%</td>
                            <td>¥ 0.81</td>
                        </tr>
                        <tr>
                            <td>≥ ¥ 0.80</td>
                            <td class="colorText">95%</td>
                            <td>¥ 0.76</td>
                        </tr>
                     </table>
                   <p class="wrapper_p">1. 我们依据您提供的电力数据,在您现有的 </p>
                   <p class="wrapper_p toLeft">① 发票: 月份, 度数, 电费金额</p>
                   <p class="wrapper_p toLeft">② 水单: 电表户号, 尖峰平谷时分度数</p>
                   <p class="wrapper_p toLeft">③ 稽核表: 电力使用状况分析 </p>
                   <p class="wrapper_p">2. 若是您,现行已享有电费优惠,均价低于0.80元/度,或是您每月超过100,000度以上的使用量,我们可以提供您更好的"绿电"新能源方案</p>
                   <p class="wrapper_p">3. 举例说明: A用户提供原始电费均价为¥1.45元/度，符合折扣为80%的电费优惠方案，使用后的折扣电价为¥1.2元/度。</p>
                   <img src="../../assets/image/powerMeasure/cancel.png" class="cancel"  @click="toHidden"> 
                </div>
            </van-overlay>

            </div>
             </van-form>
        </div>
    
      
        <router-view></router-view>
    </div>
</template>
<script>
import Measure from './Measure.vue'
import {getDistributor,getSalesman,getInternational} from '../../network/addMes'
import {isPhoneNum} from '../../assets/js/basic'

    export default{
        name:'powerMeasure',
        data(){
            return{
                isFixed:true,
                 activeName_1:['apply'],
                 activeName_2:[],
                 activeName_3:[],
                 activeName_4:[],
                 activeName_5:[],
               
                 jxsArr: [],showJxs: false,titleName:'',jxsVal:'',//经销商名字
                 ywyArr:[],showYwy:false,salesmanName:'',ywyVal:'', //业务员姓名
             
                isShow:true,
                powerArr:[{transformerKva:'',transformerUtilization:''}],
                get_intoArr:[],
                customerName:'',//客户姓名
                titlePhone:'',
                companyPhone:'',//客户电话
                corporateName:'',//公司名称
                meterNumber:'',//电表户号
                distributorPhone:'',//经销商电话
                salesmanPhone:'',//业务员电话

                enName:'经销商名称',
                enPhone:'经销商电话',
                usName:'业务员名称',
                usPhone:'业务员电话',

                overlayShow:false,
            }
        },
        components:{Measure},
        methods:{
            toHidden(){
                this.overlayShow = false;
            },
            toSelectJxs(){
                if(this.$route.query.titleNumber){
                    this.$toast('已指定经销商')
                }else{
                    this.showJxs = true
                } 
            },
            onConfirm(value) {
                this.titleName = value.titleName;
                this.showJxs = false;
                this.jxsVal = value.titleName;
                this.distributorPhone = value.distributorPhone;
                getSalesman('',value.titleNumber).then((res)=>{
                    if(res.success){
                         this.ywyArr = res.data
                    }
                })
            },
            onConfirmYwy(value) {
                this.salesmanName = value.salesman;
                this.showYwy = false;
                this.ywyVal = value.salesman;
                this.salesmanPhone = value.salesmanPhone;
                sessionStorage.setItem('ywyName',value.salesman);
                sessionStorage.setItem('ywyPhone',value.salesmanPhone);
            },
            toAddPower(){
                this.powerArr.push({})
            },
            toDelectPower(index){
                this.powerArr.splice(index,1);
            },
            transformerUtilization(index){
                return 'electricTransformer['+index+'].transformerUtilization'
            },
            transformerKva(index){
                return 'electricTransformer['+index+'].transformerKva'
            },
            onSubmit(values) {
              for (const iterator in values) {
                    if(Array.isArray(values[iterator])){
                        values[iterator] = values[iterator][0] ? values[iterator][0] :''
                        // values[iterator] = values[iterator].toString();
                    }   
              }
               if(values['titleName'].length == 0){
                    this.$toast('请选择经销商')
                    return
               }else if(values['salesman'].length == 0){
                        this.$toast('请选择业务员')
                        return
               }else if(values['corporateName'].length == 0){
                        this.$toast('请填写公司名称')
                        return
                }else if(values['customerName'].length == 0){
                        this.$toast('请填写联系人姓名')
                        return
                }
                else if(values['companyPhone'].length == 0){
                        this.$toast('请填写联系人手机号')
                        return
                }else if(!isPhoneNum(values['companyPhone'])){
                    this.$toast('请填写正确的手机号')
                        return
                }
                else if(values['meterNumber'].length == 0){
                        this.$toast('请填写电表户号')
                        return
                }else
                 if(values['assessmentEnclosure1[0].totalCost'] == null || values['assessmentEnclosure1[0].totalCost'] == ''){
                        this.$toast('请至少填写一个发票')
                        return
                        
                }else if(values['assessmentEnclosure3[0].flatValley'] == null && values['assessmentEnclosure3[0].highPeakdegree'] == null && values['assessmentEnclosure3[0].tipPeakdegree'] == null && values['assessmentEnclosure3[0].troughDegree'] == null){
                    this.$toast('请至少填写一个水单')
                        return
                }

                localStorage.setItem('measure',JSON.stringify(values))
               this.$router.push({
                     path: "powerMeasure/powerTest",
                });
            },
        },
        beforeCreate(){
            document.querySelector('body').setAttribute('style','background-color:#F7F7F7')
            if (this.$route.path.indexOf("powerTest") != -1 || this.$route.path.indexOf("basicMessage") != -1 ) {
                this.isShow = false;
            }
           let  titleNumber = this.$route.query.titleNumber;
           let  type = this.$route.query.type;//类型
           getDistributor('',type).then((res)=>{
                if(res.success){
                    this.jxsArr = res.data; 
                }
           });
           if(titleNumber){
                getDistributor('','',titleNumber).then((res)=>{
                    if(res.success){
                       this.titleName =  res.data[0].titleName;
                       this.distributorPhone = res.data[0].distributorPhone;
                    }
            });
                getSalesman('',titleNumber).then((res)=>{
                    if(res.success){
                        this.ywyArr = res.data;
                    }
                })
           }
           getInternational(type).then((res)=>{//获取栏位
                if(res.success){
                    this.enName = res.data.enName;
                    this.enPhone = res.data.enPhone;
                    this.usName = res.data.usName;
                    this.usPhone = res.data.usPhone;
                    sessionStorage.setItem('enName',res.data.enName);
                    sessionStorage.setItem('enPhone',res.data.enPhone);
                    sessionStorage.setItem('usName',res.data.usName);
                    sessionStorage.setItem('usPhone',res.data.usPhone);
                }
           })
        },
        watch: {
            $route: {
                handler: function (val) {
                    if (val.path.indexOf("powerTest") != -1 || val.path.indexOf("basicMessage") != -1 ) {
                    this.isShow = false;
                    } else {
                    this.isShow = true;
                    }
                },
            },
        }
    }
</script>
<style scoped>
@import "../../assets/css/measure.css";
.titleDelectDiv{
    width: 100%;
    height: 70px;
    position: relative;
    background: #ECF6F6;
}
.delect{
    width: 83px;
    line-height: 40px;
    border: 1px solid #01A4A4;
    border-radius: 8px;
    text-align: center;

    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #01A4A4;
    position: absolute;
    top: 15px;
    right: 24px;
}
.title{
    
    line-height: 70px;
    font-size: 25px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #01A4A4;
    margin: 0px 0px;
}
.powerDiv{
    position: relative;
    margin-top: 100px;
    height:calc(100vh - 0px);
    overflow: scroll;
}
.toTopHigh{
    margin-top:10px
}
.tryTest{
    width: 88%;
    line-height: 80px;
    background: #06B2B2;
    border-radius: 40px;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    margin: 100px auto;
    margin-bottom: 0;
    display: block;
}
.addDiv{
    border-top: solid 1px #EEF0FC;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
}
.addDiv img{
    width: 41px;
    height: 41px;
    margin-right: 12px;
}
.addDiv p{
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #01A4A4;
    line-height: 130px;
   
}
.salePlan{
    width: 88%;
    line-height: 80px;
    border: 1px solid #06B2B2;
    border-radius: 40px;
    text-align: center;
    font-size: 34px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #029999;
    display: block;
    margin: 20px auto;
    background: #F1F1F2;
}
.wrapper {
    display: flex;
    /* align-items: center;
    justify-content: center; */
    height: 100%;
    flex-direction: column;
  }
  .wrapper_p{
    color: #E6E4E4;
    font-size: 26px;
    line-height: 45px;
    padding-left: 60px;
    padding-right: 60px;
  }
  table{
    width: 88%;
    margin: 0 auto;
    border-spacing: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    box-shadow: 3px 6px 19px 2px rgba(210,231,231,0.7);
    margin-top: 200px;
    background: #ffffff;
    margin-bottom: 40px;
}
.titleTB{
    text-align: center;
    line-height: 100px;
    background: #06B2B2;
    font-size: 35px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFEFE;
}
table tr td{
    text-align: center;
    height: 98px;
}
.cancel{
    width: 50px;
    height: 50px;
    position: absolute;
    right: 15px;
    top: 150px;

  }
</style>
<style scoped lang="less">
/deep/ .van-field__control{
    text-align: right;
}
/deep/ .van-field__label span{
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
}
/deep/ .van-field__label{
    flex: 1;
}
</style>
